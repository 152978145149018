import React, { Component } from 'react';
import './App.css';
import GameList from "./GameList";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink} from 'reactstrap';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar color="dark" light expand="md">
          <img src={window.location.origin + '/cwp_logo.jpg'} className="logo" alt="logo"/>
          <NavbarBrand className="text-white" href="/"> Grier 2019</NavbarBrand>
          <Nav className="ml-auto" navbar>
          <NavItem>
                <NavLink className="text-white" href="https://www.coloradowaterpolo.org">Colorado Water Polo</NavLink>
              </NavItem>
          </Nav>
        </Navbar>
        <br></br>
        <GameList />
      </div>
    );
  }
}

export default App;
