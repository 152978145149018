import config from "../config";

/**
* Load the games from the spreadsheet
* Get the right values from it and assign.
*/

function whiteResult(white, dark){
  if (parseInt(white) > parseInt(dark)){
    return 'win';
  } else {
    return 'loss';
  }
}

function darkResult(white, dark){
  if (parseInt(dark) > parseInt(white)){
    return 'win';
  } else {
    return 'loss';
  }
}

function rowColor(agegroup){
  switch(agegroup){
    case "10U":
      return "Red"
    case "12U GOLD":
      return "GoldenRod"
    case "12U SILVER":
      return "SlateGray"
    case "14U GOLD":
      return "Gold"
    case "14U SILVER":
      return "Silver"
    case "16U":
      return "RoyalBlue"
    case "18U":
      return "Lime"
    case "G":
      return "Magenta"
  }
}

export function load(callback) {
  window.gapi.client.load("sheets", "v4", () => {
    window.gapi.client.sheets.spreadsheets.values
      .get({
        spreadsheetId: config.spreadsheetId,
        range: "Master!A2:I81"
      })
      .then(
        response => {
          const data = response.result.values;
          const games = data.map(game => ({
            num: game[0],
            course: game[1],
            day: game[2],
            time: game[3],
            age: game[4],
            white: game[5],
            whitescore: game[6],
            whiteresult: whiteResult(game[6], game[8]),
            dark: game[7],
            darkscore: game[8],
            darkresult: darkResult(game[6], game[8]),
            rowcolor: rowColor(game[4])
          })) || [];
          callback({
            games
          });
        },
        response => {
          callback(false, response.result.error);
        }
      );
  });
}