import React, { Component } from 'react';
import config from "../config";
import { load } from '../helpers/spreadsheet';
import { Container, Row, Col, Table } from 'reactstrap';


class GameList extends Component {

  componentDidMount() {
    // 1. Load the JavaScript client library.
    window.gapi.load("client", this.initClient);
  }

  initClient = () => {
    // 2. Initialize the JavaScript client library.
    window.gapi.client
      .init({
        apiKey: config.apiKey,
        // Your API key will be automatically added to the Discovery Document URLs.
        discoveryDocs: config.discoveryDocs
      })
      .then(() => {
      // 3. Initialize and make the API request.
      load(this.onLoad);
    });
  };

  onLoad = (data, error) => {
    if (data) {
      const games = data.games;
      this.setState({ games });
    } else {
      this.setState({ error });
    }
  };

  state = {
    games: [],
    error: null
  }

  render() {
    const { games, error } = this.state;
    if (error) {
      return <div>{this.state.error}</div>;
    }

    let friday = games.filter( function (game) {
      return game.day === '11/8/2019'
    });

    let saturday = games.filter( function (game) {
      return game.day === '11/9/2019'
    });

    let sunday = games.filter( function (game) {
      return game.day === '11/10/2019'
    });

    return (
      <Container>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>Game Number</th>
                  <th>Date</th>
                  <th>Location</th>
                  <th>Time</th>
                  <th>Division</th>
                  <th className="text-center">Teams - Scores</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                  <td className="bg-light" colSpan='6'><p>Friday</p></td>
              </tr>
                {friday.map((game, i) => (
                  <tr key={game.num} style={{backgroundColor: game.rowcolor}}>
                    <td className='game'>{game.num}</td>
                    <td className='game'>{game.day}</td>
                    <td className='game'>{game.course}</td>
                    <td className='game'>{game.time}</td>
                    <td className='game'>{game.age}</td>
                    <td>
                      <Table className="scores" size="sm">
                        <tbody>
                          <tr colSpan='2' className={game.whiteresult} >
                            <td className='text-left text-nowrap td-score'>White: {game.white}</td>
                            <td className='text-right text-nowrap td-score'>{game.whitescore}</td>
                          </tr>
                          <tr colSpan='2' className={game.darkresult} >
                            <td className='text-left text-nowrap '>Dark: {game.dark}</td>
                            <td className='text-right'>{game.darkscore}</td>
                          </tr>
                          </tbody>
                      </Table>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="bg-light" colSpan='6'><p>Saturday</p></td>
                </tr>
                {saturday.map((game, i) => (
                  <tr key={game.num} style={{backgroundColor: game.rowcolor}}>
                    <td className='game'>{game.num}</td>
                    <td className='game'>{game.day}</td>
                    <td className='game'>{game.course}</td>
                    <td className='game'>{game.time}</td>
                    <td className='game'>{game.age}</td>
                    <td className='game' colSpan='2'>
                      <Table className="scores" size="sm">
                        <tbody>
                          <tr colSpan='2' className={game.whiteresult} >
                            <td className='text-left text-nowrap td-score'>White: {game.white}</td>
                            <td className='text-right text-nowrap td-score'>{game.whitescore}</td>
                          </tr>
                          <tr colSpan='2' className={game.darkresult} >
                            <td className='text-left text-nowrap '>Dark: {game.dark}</td>
                            <td className='text-right'>{game.darkscore}</td>
                          </tr>
                          </tbody>
                      </Table>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="bg-light" colSpan='6'><p>Sunday</p></td>
                </tr>
                {sunday.map((game, i) => (
                  <tr key={game.num} style={{backgroundColor: game.rowcolor}}>
                    <td className='game'>{game.num}</td>
                    <td className='game'>{game.day}</td>
                    <td className='game'>{game.course}</td>
                    <td className='game'>{game.time}</td>
                    <td className='game'>{game.age}</td>
                    <td className='game' colSpan='2'>
                      <Table className="scores" size="sm">
                        <tbody>
                          <tr colSpan='2' className={game.whiteresult} >
                            <td className='text-left text-nowrap td-score'>White: {game.white}</td>
                            <td className='text-right text-nowrap td-score'>{game.whitescore}</td>
                          </tr>
                          <tr colSpan='2' className={game.darkresult} >
                            <td className='text-left text-nowrap '>Dark: {game.dark}</td>
                            <td className='text-right'>{game.darkscore}</td>
                          </tr>
                          </tbody>
                      </Table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default GameList;